import React from 'react';
import { bankIdRequests } from '@constants';
import Page from '@components/containers/Page';
import Section from '@components/containers/Section';
import BankIdButtonGroup from '@components/modules/BankIdButtonGroup';

const Login = () => (
  <Page noLinks>
    <Section narrow>
      <BankIdButtonGroup requestType={bankIdRequests.LOGIN} />
    </Section>
  </Page>
);

export default Login;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BankIdWhite from '@assets/img/icons/bankid_white.svg?inline';
import BankIdBlue from '@assets/img/icons/bankid_blue.svg?inline';

import Button from '@components/elements/Button';
import FlexBox, { StyledFlexBox } from '@components/containers/FlexBox';
import style from '@constants/style';
import modalTypes from '@components/modals/types';
import { showModal } from '@redux/actions/ui';
import { bankIdRequests, bankIdTypes } from '@constants';
import Icon, { StyledIcon } from '@components/elements/Icon';

const StyledButtonContainer = styled(StyledFlexBox)`
  justify-content: center;
  align-items: center;

  ${StyledIcon} {
    margin-right: ${style.margin.regular};
    margin-left: ${style.margin.none};
  }
`;

const mapButtonCopy = {
  [bankIdRequests.LOGIN]: 'Logga in med BankID',
  [bankIdRequests.SIGN_DOCUMENT]: 'Öppna BankID',
};

const BankIdButtonGroup = ({ requestType, direction, payload }) => {
  const dispatch = useDispatch();

  const onClick = useCallback((e, bankIdType) => {
    e.preventDefault();

    dispatch(showModal(
      {
        type: modalTypes.BANK_ID,
        props: {
          request: requestType,
          payload: { bankIdType, ...payload },
        },
      },
    ));
  }, [dispatch, requestType, payload]);

  return (
    <FlexBox gap="regular" margin="regular" flexDirection={direction}>
      <Button
        onClick={(e) => onClick(e, bankIdTypes.SAME_DEVICE)}
        primaryColor={BankIdWhite}
        secondaryColor={BankIdBlue}
      >
        <StyledButtonContainer>
          <Icon
            icon={BankIdWhite}
            iconHeight={style.fontSize.big}
            iconWidth={style.fontSize.big}
          />
          {mapButtonCopy[requestType]}
        </StyledButtonContainer>
      </Button>
      <Button type="secondary" onClick={(e) => onClick(e, bankIdTypes.OTHER_DEVICE)}>
        Öppna på en annan enhet
      </Button>
    </FlexBox>
  );
};

BankIdButtonGroup.propTypes = {
  requestType: PropTypes.oneOf(Object.values(bankIdRequests)).isRequired,
  direction: PropTypes.oneOf(['row', 'column']),
  payload: PropTypes.shape({}),
};

BankIdButtonGroup.defaultProps = {
  direction: 'column',
  payload: {},
};

export default BankIdButtonGroup;
